import React from 'react';
import PropTypes from 'prop-types';

import { Cover, GridResults, GridResultsVideo, IconsAlignerService, Layout, Link, SectionResultsQuote, SEO, TrustpilotWidget } from 'components';
import { routes } from 'utils/routes';
import reviewSelfies from 'utils/config/reviewSelfies';
import reviewVideos from 'utils/config/reviewVideos';

import content from './reviews.yml';

const ResultsPage = ({ pageContext: { locale }, location }) => (
  <Layout locale={locale}>
    <SEO
      title={content.title}
      description={content.description}
      lang={locale}
      keywords={content.keywords}
      location={location}
      image={content.socialImage}
    />

    <Link to={routes.apart.path} {...routes.apart} className='cta-sidetab hide-mobile'>What sets us apart?</Link>

    <Cover
      title='Smile beauty, accessible to all.'
      text="Smile beauty. Democratised.<br/>We love hearing the difference our teeth straightening innovations have on people's lives.<br/><br/>Don't take our word for it, hear it directly from those who have transformed their smiles with Straight Teeth Direct&trade;"
      button={{ label: 'Get started', ...routes.getStarted }}
      image={{ url: 'pages/results_cover.jpg', alt: 'User reviews' }}
      overlap
      trustpilot
    />

    <IconsAlignerService />

    <GridResultsVideo link={{ label: 'View more videos' }} videos={reviewVideos} className='mt-90' />
    <GridResults link={{ label: 'View more photos' }} pictures={reviewSelfies} />

    <TrustpilotWidget showReviews />

    <SectionResultsQuote noButton />
  </Layout>
);

ResultsPage.propTypes = {
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
  }).isRequired,
};

export default ResultsPage;
