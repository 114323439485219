const reviewVideos = [
  { url: require("assets/images/pages/reviews/videos/victoria.jpg"),
    alt: "Straight Teeth Direct Review by Victoria",
    wistiaHash: "h3khrajeet"},
  { url: require("assets/images/pages/reviews/videos/serpil.jpg"),
    alt: "Straight Teeth Direct Review by Serpil",
    wistiaHash: "ihefdf3jop"},
  { url: require("assets/images/pages/reviews/videos/mike.jpg"),
    alt: "Straight Teeth Direct Review by Mike",
    wistiaHash: "8iid1ml7dg"},
  { url: require("assets/images/pages/reviews/videos/alan.jpg"),
    alt: "Straight Teeth Direct Review by Alan",
    wistiaHash: "e69imvb3uc"},
  { url: require("assets/images/pages/reviews/videos/maria-cristina.jpg"),
    alt: "Straight Teeth Direct Review by Maria Cristina",
    wistiaHash: "hfhdeovwqv"},
  { url: require("assets/images/pages/reviews/videos/sharon.jpg"),
    alt: "Straight Teeth Direct Review by Sharon",
    wistiaHash: "1k5adcux9o"},
  { url: require("assets/images/pages/reviews/videos/vivek.jpg"),
    alt: "Straight Teeth Direct Review by Vivek",
    wistiaHash: "lrrcnwxe4p"},
  { url: require("assets/images/pages/reviews/videos/juliane.jpg"),
    alt: "Straight Teeth Direct Review by Juliane",
    wistiaHash: "9o8i0sqedk"},
  { url: require("assets/images/pages/reviews/videos/selina.jpg"),
    alt: "Straight Teeth Direct Review by Selina",
    wistiaHash: "pn97g3o2yn"},
  { url: require("assets/images/pages/reviews/videos/katie.jpg"),
    alt: "Straight Teeth Direct Review by Katie",
    wistiaHash: "2wijmedr39"},
  { url: require("assets/images/pages/reviews/videos/chris.jpg"),
    alt: "Straight Teeth Direct Review by Chris",
    wistiaHash: "5tmv7dfqjs"},
  { url: require("assets/images/pages/reviews/videos/andrea.jpg"),
    alt: "Straight Teeth Direct Review by Andrea",
    wistiaHash: "simnoprf6w"},
  { url: require("assets/images/pages/reviews/videos/haran.jpg"),
    alt: "Straight Teeth Direct Review by Haran",
    wistiaHash: "2fkv6w0e73"},
  { url: require("assets/images/pages/reviews/videos/karla.jpg"),
    alt: "Straight Teeth Direct Review by Karla",
    wistiaHash: "9481e4p8qu"},
  { url: require("assets/images/pages/reviews/videos/jess-hornsey.jpg"),
    alt: "Straight Teeth Direct Review by Jessica",
    wistiaHash: "kmves8v7nl"},
  { url: require("assets/images/pages/reviews/videos/nicola.jpg"),
    alt: "Straight Teeth Direct Review by Nicola",
    wistiaHash: "v6fsyn9qes"},
  { url: require("assets/images/pages/reviews/videos/jessica.jpg"),
    alt: "Straight Teeth Direct Review by Jessica",
    wistiaHash: "bwyljk0odj"},
  { url: require("assets/images/pages/reviews/videos/wolfgang.jpg"),
    alt: "Straight Teeth Direct Review by Wolfgang",
    wistiaHash: "19n9psbfyn"},
  { url: require("assets/images/pages/reviews/videos/sandra.jpg"),
    alt: "Straight Teeth Direct Review by Sandra",
    wistiaHash: "6l25pbhcvr"},
  { url: require("assets/images/pages/reviews/videos/dan.jpg"),
    alt: "Straight Teeth Direct Review by Dan",
    wistiaHash: "wtaxn6i1jx"},
  { url: require("assets/images/pages/reviews/videos/toni.jpg"),
    alt: "Straight Teeth Direct Review by Toni",
    wistiaHash: "zzfb289byp"},
  { url: require("assets/images/pages/reviews/videos/mohan.jpg"),
    alt: "Straight Teeth Direct Review by Mohan",
    wistiaHash: "i6wifl55g3"},
  { url: require("assets/images/pages/reviews/videos/lisa.jpg"),
    alt: "Straight Teeth Direct Review by Lisa",
    wistiaHash: "cdmgijwfks"},
  { url: require("assets/images/pages/reviews/videos/catherine.jpg"),
    alt: "Straight Teeth Direct Review by Catherine",
    wistiaHash: "1qqmgc3z4u"},
  { url: require("assets/images/pages/reviews/videos/natalia.jpg"),
    alt: "Straight Teeth Direct Review by Natalia",
    wistiaHash: "nedqgiqp3n"},
  { url: require("assets/images/pages/reviews/videos/alison.jpg"),
    alt: "Straight Teeth Direct Review by Alison",
    wistiaHash: "0saudddg58"},
  { url: require("assets/images/pages/reviews/videos/roxanne.jpg"),
    alt: "Straight Teeth Direct Review by Roxanne",
    wistiaHash: "h1pgtnshof"},
  { url: require("assets/images/pages/reviews/videos/joe.jpg"),
    alt: "Straight Teeth Direct Review by Joe",
    wistiaHash: "b8rm3wh8rk"},
  { url: require("assets/images/pages/reviews/videos/ellen.jpg"),
    alt: "Straight Teeth Direct Review by Ellen",
    wistiaHash: "bmqqyn5idq"},
  { url: require("assets/images/pages/reviews/videos/zlatina.jpg"),
    alt: "Straight Teeth Direct Review by Zlatina",
    wistiaHash: "v98sw45nar"},
  { url: require("assets/images/pages/reviews/videos/andreia.jpg"),
    alt: "Straight Teeth Direct Review by Andreia",
    wistiaHash: "wa7r0vlzkx"},
  { url: require("assets/images/pages/reviews/videos/maria.jpg"),
    alt: "Straight Teeth Direct Review by Maria",
    wistiaHash: "kx8ns5vgf3"},
  { url: require("assets/images/pages/reviews/videos/rebecca.jpg"),
    alt: "Straight Teeth Direct Review by Rebecca",
    wistiaHash: "syi6fc1e85"},
  { url: require("assets/images/pages/reviews/videos/sarah.jpg"),
    alt: "Straight Teeth Direct Review by Sarah",
    wistiaHash: "ujzzp9wkq0"},
  { url: require("assets/images/pages/reviews/videos/deepal.jpg"),
    alt: "Straight Teeth Direct Review by Deepal",
    wistiaHash: "3supaw1oh2"},
  { url: require("assets/images/pages/reviews/videos/adil.jpg"),
    alt: "Straight Teeth Direct Review by Adil",
    wistiaHash: "elr39hj2h2"},
  { url: require("assets/images/pages/reviews/videos/marie.jpg"),
    alt: "Straight Teeth Direct Review by Marie",
    wistiaHash: "jvhntwsedy"}
];

export default reviewVideos;
